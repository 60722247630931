import 'legacy/common/styles/style.scss';
import 'polyfills';

import Head from 'next/head';
import { AppProps } from 'next/app';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { queryClient } from 'legacy/common/utils/react-query';
import usePageTracking from 'legacy/common/hooks/usePageTracking';
import { LAUNCHDARKLY_USER_KEYS } from 'legacy/common/constants/launchdarkly';

import { initAmplitude, initApiClient } from 'init';
import config from 'config';
import { useStore } from '../src/legacy/store';

import RedirectContainer from '../src/legacy/RedirectContainer';
import ErrorBoundary from 'legacy/components/util/ErrorBoundary/ErrorBoundary';
import UnverifiedEmailCheck from 'legacy/auth/components/unverified-email/UnverifiedEmailCheck';
import { ComponentType, ReactNode } from 'react';

initAmplitude();
initApiClient();

type Props = AppProps & {
  Component: Pick<AppProps, 'Component'> & {
    getLayout?: (page: ReactNode) => typeof page;
  };
};

// This default export is required in a new `pages/_app.js` file.
const App = ({ Component, pageProps }: Props) => {
  const store = useStore(pageProps.initialReduxState);

  usePageTracking();

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>Bounce</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      <ErrorBoundary>
        <Toaster position="top-right" reverseOrder={false} />
        <Provider store={store}>
          <div id="app-content">
            {getLayout(
              <RedirectContainer Component={Component} pageProps={pageProps} />
            )}
          </div>
          <UnverifiedEmailCheck />
        </Provider>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default withLDProvider({
  clientSideID: config.LAUNCHDARKLY_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false
  },
  context: {
    kind: 'user',
    key: LAUNCHDARKLY_USER_KEYS.ANONYMOUS,
    anonymous: true
  }
})(App as ComponentType<AppProps>);
