export enum AvatarSizes {
  Xs = '6',
  Sm = '8',
  Md = '10',
  Lg = '12'
}

export enum AvatarRadii {
  Full = 'full',
  Lg = 'lg'
}

export enum AvatarGroupType {
  Stack = 'stack',
  Grid = 'grid'
}
