import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { AvatarRadii, AvatarSizes } from '../../types';

interface Props {
  src?: string;
  size?: AvatarSizes;
  radius?: AvatarRadii;
  isBordered?: boolean;
  label?: ReactNode;
  className?: string;
}

const AvatarBorder = {
  [AvatarSizes.Xs]: '1',
  [AvatarSizes.Sm]: '1',
  [AvatarSizes.Md]: '2',
  [AvatarSizes.Lg]: '2'
};

const AvatarFontSize = {
  [AvatarSizes.Xs]: 'xs',
  [AvatarSizes.Sm]: 'xs',
  [AvatarSizes.Md]: 'xs',
  [AvatarSizes.Lg]: 'lg'
};

function Avatar({
  src,
  size = AvatarSizes.Lg,
  radius = AvatarRadii.Full,
  isBordered = true,
  label,
  className
}: Props) {
  return (
    <div
      className={classNames(
        'border-primary-300 overflow-hidden flex align-center bg-white justify-center',
        `text-${AvatarFontSize[size]}`,
        `w-${size} h-${size}`,
        `rounded-${radius}`,
        isBordered && `border-${AvatarBorder[size]}`,
        className
      )}
    >
      {label || <img src={src || '/img/search/user.svg'} />}
    </div>
  );
}

export default Avatar;
