import * as Sentry from '@sentry/nextjs';
import { Area } from 'react-easy-crop/types';

export function createImage(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });
}

export async function getCroppedImage(imageSrc: string, pixelCrop: Area) {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        const error = new Error('Could not crop the image.');
        reject(error);

        Sentry.captureException(error);
        return;
      }

      resolve(blob);
    }, 'image/jpeg');
  });
}

export const isValidImage = async (
  file: File,
  requirements: {
    maxSize?: number;
    allowedTypes?: string[];
    maxWidth?: number;
    maxHeight?: number;
    aspectRatio?: number;
  }
): Promise<boolean> => {
  if (
    requirements.allowedTypes !== undefined &&
    !requirements.allowedTypes.includes(file.type)
  ) {
    return false;
  }

  if (requirements.maxSize !== undefined && file.size > requirements.maxSize) {
    return false;
  }

  if (
    requirements.maxWidth !== undefined ||
    requirements.maxHeight !== undefined ||
    requirements.aspectRatio !== undefined
  ) {
    const { width, height } = await getImageDimensions(file);

    if (requirements.maxWidth !== undefined && width > requirements.maxWidth) {
      return false;
    }

    if (
      requirements.maxHeight !== undefined &&
      height > requirements.maxHeight
    ) {
      return false;
    }

    if (
      requirements.aspectRatio !== undefined &&
      Math.abs(width / height - requirements.aspectRatio) > 0.01
    ) {
      return false;
    }
  }

  return true;
};

const getImageDimensions = async (
  file: File
): Promise<{ width: number; height: number }> =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.src = URL.createObjectURL(file);
  });
